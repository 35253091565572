import { render, staticRenderFns } from "./DataListRecommendedSefetyMeasures.vue?vue&type=template&id=0d0d4339&scoped=true&"
import script from "./DataListRecommendedSefetyMeasures.vue?vue&type=script&lang=js&"
export * from "./DataListRecommendedSefetyMeasures.vue?vue&type=script&lang=js&"
import style0 from "./DataListRecommendedSefetyMeasures.vue?vue&type=style&index=0&id=0d0d4339&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d0d4339",
  null
  
)

export default component.exports